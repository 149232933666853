import * as React from "react";

interface IProps {}

const Test = (props: IProps) => {
  return (
    <main>
      <h1>Test</h1>
    </main>
  );
};

export default Test;
